import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  fetchWorkshops as _fetchWorkshops,
  updateWorkshopPermission as _updateWorkshopPermission,
  createWorkshopPermission as _createWorkshopPermission,
  deleteWorkshopPermission as _deleteWorkshopPermission,
  toggleWorkshopMessageSubscription as _toggleWorkshopMessageSubscription,
} from '../../../actions/workshopActions';
import { withRouter } from 'react-router';
import PermissionRow from './PermissionRow';


class SettingsWorkshopPermissions extends React.Component {
  state = {
    selectedWorkshop: undefined,
  };

  componentDidMount() {
    const { fetchWorkshops } = this.props;
    fetchWorkshops({ordering: 'name'});
  }

  getWorkshops = () => {
    const { workshops, service, selectedWorkshopId } = this.props;

    if (service === SERVICE_WORKSHOP && selectedWorkshopId) {
      return workshops.filter((ws) => ws.id == selectedWorkshopId);
    }

    return workshops;
  };

  componentWillReceiveProps(nextProps) {
    const { selectedWorkshop } = this.state;
    const { user } = this.props;

    const workshops = this.getWorkshops();

    if ((!workshops || !workshops.length) && (nextProps.workshops && nextProps.workshops.length)) {
      this.setState({
        selectedWorkshop: workshops[0],
      });
    }

    if (nextProps.workshops && nextProps.workshops.length && selectedWorkshop) {
      this.setState({
        selectedWorkshop: nextProps.workshops.find((workshop) => workshop.id === selectedWorkshop.id),
      });
    }
  }

  getDisplayTextForRole = (role) => {
    const { t } = this.props;

    const roleDisplayMap = {
      employee: t('roleEmployee'),
      manager: t('roleManager'),
      admin: t('roleAdmin'),
    };

    return roleDisplayMap[role];
  };


  getWorkshopOptions = () => {
    const workshops = this.getWorkshops();

    return workshops.map((workshop) => {
      return {
        label: workshop.name,
        value: workshop.id,
      };
    });
  };

  workshopChanged = (selected) => {
    const workshopId = Number.parseInt(selected.value);
    const workshops = this.getWorkshops();

    this.setState({
      selectedWorkshop: workshops.find((workshop) => workshopId === workshop.id),
    });
  };

  getSelectedWorkshop = () => {
    const workshops = this.getWorkshops();

    let { selectedWorkshop } = this.state;

    if (!selectedWorkshop && workshops.length) {
      [selectedWorkshop] = workshops;
    }

    return selectedWorkshop;
  };

  canEditPermission = (permission, userRole) => {
    const { user } = this.props;

    if (user.is_staff) {
      return true;
    }

    if (permission.user && user.id === permission.user.id) {
      return false;
    }

    return userRole === 'admin';
  };

  messageSubscriptionChanged = (value) => {
    const { toggleWorkshopMessageSubscription } = this.props;
    toggleWorkshopMessageSubscription(
      this.getSelectedWorkshop().id,
      { has_message_subscription: value },
    );
  };

  render() {
    const {
      t,
      updateWorkshopPermission,
      createWorkshopPermission,
      deleteWorkshopPermission,
      service,
      selectedWorkshopId
    } = this.props;

    const selectedWorkshop = this.getSelectedWorkshop();

    const isAllowedToAddPermissions = selectedWorkshop && ['admin', 'manager'].includes(selectedWorkshop.user_role);

    const permissions = selectedWorkshop ? selectedWorkshop.permissions : [];

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBSubHeading>
              {t('workshopPermissionsHeading')}
            </CBSubHeading>



            <div style={getStyle(screenSize, styles, 'workshopSelectorContainer')}>
              <CBSelectField
                containerStyle={getStyle(screenSize, styles, 'selectContainer')}
                inputStyle={getStyle(screenSize, styles, 'selectField')}
                optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                options={this.getWorkshopOptions()}
                placeholder=''
                labelText={t('labelWorkshop')}
                value={selectedWorkshop && selectedWorkshop.id}
                onChange={this.workshopChanged}
                disabled={service === SERVICE_WORKSHOP && selectedWorkshopId}
              />
            </div>


            {
              selectedWorkshop && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'permissionTypeContainer')}>
                    <CBLabelAndValue
                      label={t('permissionType')}
                      value={this.getDisplayTextForRole(
                        selectedWorkshop && selectedWorkshop.user_role,
                      )}
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'switchContainer')}>
                      <CBLabelAndSwitch
                        labelText={t('receiveCareServiceNotificationsLabel')}
                        size='medium'
                        checked={selectedWorkshop.user_has_message_subscription}
                        onChange={this.messageSubscriptionChanged}
                      />
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('userTableHeading')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('descriptionTableHeading')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('permissionTableHeading')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        permissions && permissions.map((permission) =>
                          (
                            <PermissionRow
                              key={permission.id}
                              permission={permission}
                              updatePermission={updateWorkshopPermission}
                              createPermission={createWorkshopPermission}
                              deletePermission={deleteWorkshopPermission}
                              canEdit={this.canEditPermission(permission, selectedWorkshop.user_role)}
                              userRole={selectedWorkshop.user_role}
                              isWorkshopPermission
                            />
                          ))
                      }
                      {
                        isAllowedToAddPermissions && (
                          <PermissionRow
                            isNew
                            workshop={selectedWorkshop}
                            permission={{
                              user_email: '',
                              role: 'employee',
                              description: '',
                            }}
                            createPermission={createWorkshopPermission}
                            canEdit
                            userRole={selectedWorkshop.user_role}
                            isWorkshopPermission
                          />
                        )
                      }
                    </tbody>
                  </table>
                </React.Fragment>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    workshopSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    switchContainer: {
      marginTop: 32,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    workshops: state.workshop.workshops,
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWorkshops: _fetchWorkshops,
    updateWorkshopPermission: _updateWorkshopPermission,
    createWorkshopPermission: _createWorkshopPermission,
    deleteWorkshopPermission: _deleteWorkshopPermission,
    toggleWorkshopMessageSubscription: _toggleWorkshopMessageSubscription,
  },
)(withRouter(translate('Permissions')(SettingsWorkshopPermissions)));
