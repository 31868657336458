import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import i18n from 'i18next';
import { styleMerge, getStyle, TYPE_WORKSHOP_ALL } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';
import {
  searchMaintenances as _searchMaintenances,
  searchTreatments as _searchTreatments,
} from '../../actions/searchActions';
import CBSelectField from './form_components/CBSelectField';
import CBButtonGarageAction from './CBButtonGarageAction';

class VehicleActionBar extends Component {
  render() {
    const {
      t,
      updateVehicles,
      selectedVehicles,
      sendMessages,
      deleteVehicles,
      listType
    } = this.props;
    const selectedCount = selectedVehicles.length;
    const buttonsDisabled = selectedCount < 1;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'countTextContainer')}>
              <p style={getStyle(screenSize, styles, 'selectedCountText')}>
                {`${selectedCount} valittu`}
              </p>
            </div>
            <div style={getStyle(screenSize, styles, 'actionButtonsContainer')}>
              <div style={getStyle(screenSize, styles, 'actionButtonContainer')}>
                <CBButtonGarageAction
                  onClick={updateVehicles}
                  mainIcon='/img/icon_update.svg'
                  text={t('updateVehiclesButton')}
                  disabled={buttonsDisabled}
                  additionalStylesMainIcon={{ height: 30, width: 30 }}
                />
              </div>
              {
                listType !== TYPE_WORKSHOP_ALL && (
                  <div style={getStyle(screenSize, styles, 'actionButtonContainer')}>
                    <CBButtonGarageAction
                      onClick={sendMessages}
                      mainIcon='/img/icon_message_solid.svg'
                      text={t('sendMessageButton')}
                      disabled={buttonsDisabled}
                      additionalStylesMainIcon={{ height: 25, width: 25 }}
                    />
                  </div>
                )
              }

              {/*
              <div style={styleMerge(getStyle(screenSize, styles, 'actionButtonContainer'), screenSize != 'small' ? { width: 150 } : {})}>
                <CBButtonGarageAction
                  onClick={deleteVehicles}
                  mainIcon='/img/icon_trashcan.svg'
                  text={t('deleteButton')}
                  disabled={buttonsDisabled}
                  additionalStylesMainIcon={{ height: 30, width: 30 }}
                />
              </div>
              */}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    actionButtonContainer: {
      width: 175,
    },
    selectedCountText: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      marginBottom: 0,
    },
    actionButtonsContainer: {
      marginLeft: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    countTextContainer: {
      minWidth: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  small: {
    actionButtonContainer: {
      width: 75,
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('VehicleListView')(VehicleActionBar));
