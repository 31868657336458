import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import MessageListView from './MessagesViewComponents/MessageListView';

class MessagesLayoutView extends React.Component {
  componentDidMount = () => {
  };

  render() {
    const {
      history, t,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <MessageListView />
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {

    },
  },
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('MessagesLayoutView')(MessagesLayoutView));
