import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSearchInput from '../../shared/CBSearchInput';
import OrganizationListItem from './OrganizationListItem';
import {
  createOrganization as _createOrganization,
  fetchOrganizations as _fetchOrganizations,
  updateOrganization as _updateOrganization,
} from '../../../actions/organizationActions';
import OrganizationForm from './OrganizationForm';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';

class SettingsOrganization extends React.Component {
  state = {
  };

  componentDidMount = () => {
    const { fetchOrganizations, organizations, selectedOrganizationId } = this.props;
    fetchOrganizations();
  };

  render() {
    const {
      t, organizationRequest, updateOrganization, createOrganization, user, organizations, selectedOrganizationId
    } = this.props;

    const selectedOrganization = organizations.find((org) => org.id == selectedOrganizationId)

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editOrganizationContainer')}>
              <CBHeaderTextWithHelp text={t('editOrganizationHeading')} />
              {
                selectedOrganization && (
                  <OrganizationForm
                    user={user}
                    organization={selectedOrganization}
                    organizationRequest={organizationRequest}
                    isOwnOrganization={false}
                    updateOrganization={updateOrganization}
                    createOrganization={createOrganization}
                  />
                )
              }
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    editOrganizationContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      minHeight: 500,
      minWidth: 800,
      maxWidth: 1200,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    editOrganizationContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
      width: '100%',
      minWidth: 'auto',
      maxWidth: 'auto',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editOrganizationContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
      minWidth: 'auto',
      maxWidth: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    organizationRequest: state.organization,
    organizations: state.organization.organizations,
    loading: state.organization.organizationListStatus.loading,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations,
    updateOrganization: _updateOrganization,
    createOrganization: _createOrganization,
  },
)(translate('SettingsView')(SettingsOrganization));
