import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET } from '../../../utils/utils';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  fetchGarages as _fetchGarages,
  updateGaragePermission as _updateGaragePermission,
  createGaragePermission as _createGaragePermission,
  deleteGaragePermission as _deleteGaragePermission,
  toggleGarageOrganizationSharing as _toggleGarageOrganizationSharing,
  toggleGarageMessageSubscription as _toggleGarageMessageSubscription,
} from '../../../actions/garageActions';
import PermissionRow from './PermissionRow';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import { withRouter } from 'react-router';


class SettingsGaragePermissions extends React.Component {
  state = {
    selectedGarage: undefined,
    organizations: [],
    filteredGarages: [],
  };

  componentDidMount() {
    const {
      fetchGarages,
      garages,
    } = this.props;

    fetchGarages();

    if (garages && garages.length) {
      const organizations = this.parseOrganizations(garages);
      this.filterGarages(organizations[0].id);

    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganizationId, garages } = this.props;

    if (selectedOrganizationId != prevProps.selectedOrganizationId) {
      const organizations = this.parseOrganizations(garages);
      const filteredGarages = this.filterGarages(organizations[0].id, garages);
      this.setState({
        selectedOrganization: organizations[0],
        selectedGarage: filteredGarages[0],
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { selectedGarage } = this.state;
    const { garages } = this.props;

    if (((!garages || !garages.length) && (nextProps.garages && nextProps.garages.length))) {
      const organizations = this.parseOrganizations(nextProps.garages);
      const filteredGarages = this.filterGarages(organizations[0].id, nextProps.garages);
      this.setState({
        selectedOrganization: organizations[0],
        selectedGarage: filteredGarages[0],
      });
    }

    if (nextProps.garages && nextProps.garages.length && selectedGarage) {
      this.setState({
        selectedGarage: nextProps.garages.find((garage) => garage.id === selectedGarage.id),
      });
    }
  }

  getDisplayTextForRole = (role, canAddVehicles) => {
    const { t } = this.props;

    const roleDisplayMap = {
      employee: t('roleEmployee'),
      manager: t('roleManager'),
      admin: t('roleAdmin'),
    };

    let roleDisplay = roleDisplayMap[role];

    if (role !== 'admin' && canAddVehicles) {
      roleDisplay += ` ${t('roleCanAdd')}`;
    }

    return roleDisplay;
  };

  parseOrganizations = (garages) => {
    const { service, selectedOrganizationId } = this.props;

    const unfilteredOrganizations = garages.map((garage) => {
      return { id: garage.organization_id, name: garage.organization_name };
    });

    const usedIds = [];
    let organizations = unfilteredOrganizations.filter((organization) => {
      if (!usedIds.includes(organization.id)) {
        usedIds.push(organization.id);
        return true;
      }
      return false;
    });

    if (service === SERVICE_FLEET && selectedOrganizationId) {
      organizations = organizations.filter((org) => org.id == selectedOrganizationId);
    }

    organizations.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ organizations });

    return organizations;
  };

  filterGarages = (organizationId, garages) => {
    if (!garages) {
      garages = this.props.garages;
    }

    const filteredGarages = garages.filter((garage) => garage.organization_id == organizationId);

    this.setState({ filteredGarages });

    return filteredGarages;
  };

  getOrganizationOptions = () => {
    let { organizations } = this.state;
    const { garages } = this.props;

    if (!organizations) {
      organizations = this.parseOrganizations(garages);
    }

    return organizations.map((org) => {
      return {
        label: org.name,
        value: org.id,
      };
    });
  };

  organizationChanged = (selected) => {
    const { organizations } = this.state;
    const organizationId = Number.parseInt(selected.value);
    const filteredGarages = this.filterGarages(organizationId);

    this.setState({
      selectedOrganization: organizations.find((organization) => organizationId === organization.id),
      selectedGarage: filteredGarages[0],
    });
  };

  getSelectedOrganization = () => {
    let {
      organizations,
    } = this.state;

    const { garages } = this.props;

    let { selectedOrganization } = this.state;

    if (!organizations && garages && garages.length) {
      organizations = this.parseOrganizations(garages);
    }

    if (!selectedOrganization && organizations.length) {
      [selectedOrganization] = organizations;
    }

    return selectedOrganization;
  };

  getGarageOptions = () => {
    const { filteredGarages } = this.state;
    return filteredGarages.map((garage) => {
      return {
        label: garage.name,
        value: garage.id,
      };
    });
  };

  garageChanged = (selected) => {
    const garageId = Number.parseInt(selected.value);
    const { garages } = this.props;
    this.setState({
      selectedGarage: garages.find((garage) => garageId === garage.id),
    });
  };

  getSelectedGarage = () => {
    const {
      filteredGarages,
    } = this.state;
    let { selectedGarage } = this.state;

    if (!selectedGarage && filteredGarages.length) {
      [selectedGarage] = filteredGarages;
    }

    return selectedGarage;
  };

  canEditPermission = (permission, userRole) => {
    const { user } = this.props;

    if (user.is_staff) {
      return true;
    }

    if (permission.user && user.id === permission.user.id) {
      return false;
    }

    return userRole === 'admin'/* || (userRole === 'manager' && permission.role !== 'admin') */;
  };

  organizationSharingChanged = (value) => {
    const { toggleGarageOrganizationSharing } = this.props;
    toggleGarageOrganizationSharing(this.getSelectedGarage().id, { organization_sharing: value });
  };

  messageSubscriptionChanged = (value) => {
    const { toggleGarageMessageSubscription } = this.props;
    toggleGarageMessageSubscription(
      this.getSelectedGarage().id,
      { has_message_subscription: value },
    );
  };

  navigateToGarage = (garage) => {
    const { history } = this.props;
    history.push(`/organization_garage/${garage.id}/`);
  };

  render() {
    const {
      t,
      updateGaragePermission,
      createGaragePermission,
      deleteGaragePermission,
      service,
      selectedOrganizationId
    } = this.props;

    const selectedOrganization = this.getSelectedOrganization();
    const selectedGarage = this.getSelectedGarage();

    const isAllowedToAddPermissions = selectedGarage && ['admin'/* , 'manager' */].includes(selectedGarage.user_role);

    const permissions = selectedGarage ? selectedGarage.permissions : [];

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBSubHeading>
              {t('garagePermissionsHeading')}
            </CBSubHeading>

            <CBSelectField
              containerStyle={getStyle(screenSize, styles, 'selectContainer')}
              inputStyle={getStyle(screenSize, styles, 'selectField')}
              optionStyle={getStyle(screenSize, styles, 'optionStyle')}
              options={this.getOrganizationOptions()}
              placeholder=''
              value={selectedOrganization && selectedOrganization.id}
              onChange={this.organizationChanged}
              labelText={t('labelOrganization')}
              labelTextStyle={{ fontFamily: 'TitilliumWeb-SemiBold' }}
              disabled={service === SERVICE_FLEET && selectedOrganizationId}
            />

            {
              selectedOrganization && (
                <div style={getStyle(screenSize, styles, 'garageSelectorContainer')}>
                  <CBSelectField
                    containerStyle={getStyle(screenSize, styles, 'selectContainer')}
                    inputStyle={getStyle(screenSize, styles, 'selectField')}
                    optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                    options={this.getGarageOptions()}
                    placeholder=''
                    labelText={t('labelGarage')}
                    value={selectedGarage && selectedGarage.id}
                    onChange={this.garageChanged}
                  />
                  {
                    selectedGarage && (
                      <CBButtonWithIconSmall text='Siirry autotalliin' onClick={() => this.navigateToGarage(selectedGarage)} />
                    )
                  }
                </div>
              )
            }

            {
              selectedGarage && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'permissionTypeContainer')}>
                    <CBLabelAndValue
                      label={t('permissionType')}
                      value={this.getDisplayTextForRole(
                        selectedGarage && selectedGarage.user_role,
                        selectedGarage && selectedGarage.user_can_add_vehicles,
                      )}
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'switchContainer')}>
                    {
                      selectedGarage.user_role === 'admin' && (
                        <CBLabelAndSwitch
                          labelText={t('shareWholeGarageLabel')}
                          size='medium'
                          checked={selectedGarage.organization_sharing}
                          onChange={this.organizationSharingChanged}
                        />
                      )
                    }
                    <CBLabelAndSwitch
                      labelText={t('receiveNotificationsLabel')}
                      size='medium'
                      checked={selectedGarage.user_has_message_subscription}
                      onChange={this.messageSubscriptionChanged}
                    />
                  </div>
                  {
                    selectedGarage.organization_sharing && (
                      <table>
                        <thead>
                          <tr>
                            <th style={getStyle(screenSize, styles, 'tableHeading')}>
                              {t('userTableHeading')}
                            </th>
                            <th style={getStyle(screenSize, styles, 'tableHeading')}>
                              {t('descriptionTableHeading')}
                            </th>
                            <th style={getStyle(screenSize, styles, 'tableHeading')}>
                              {t('permissionTableHeading')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            permissions && permissions.map((permission) =>
                              (
                                <PermissionRow
                                  key={permission.id}
                                  permission={permission}
                                  updatePermission={updateGaragePermission}
                                  createPermission={createGaragePermission}
                                  deletePermission={deleteGaragePermission}
                                  canEdit={this.canEditPermission(permission, selectedGarage.user_role)}
                                  userRole={selectedGarage.user_role}
                                  isGaragePermission
                                />
                              ))
                          }
                          {
                            isAllowedToAddPermissions && (
                              <PermissionRow
                                isNew
                                garage={selectedGarage}
                                permission={{
                                  user_email: '',
                                  role: 'employee',
                                  description: '',
                                }}
                                createPermission={createGaragePermission}
                                canEdit
                                userRole={selectedGarage.user_role}
                                isGaragePermission
                              />
                            )
                          }
                        </tbody>
                      </table>
                    )
                  }
                </React.Fragment>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    garageSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    switchContainer: {
      marginTop: 32,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    garages: state.garage.garages,
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGarages: _fetchGarages,
    updateGaragePermission: _updateGaragePermission,
    createGaragePermission: _createGaragePermission,
    deleteGaragePermission: _deleteGaragePermission,
    toggleGarageOrganizationSharing: _toggleGarageOrganizationSharing,
    toggleGarageMessageSubscription: _toggleGarageMessageSubscription,
  },
)(withRouter(translate('Permissions')(SettingsGaragePermissions)));
