import { userState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray, mergeObjectList } from '../utils/utils';

export default function (state = userState, action) {
  switch (action.type) {
    case 'FETCH_USERS_REQUEST': {
      return {
        ...state,
        usersStatus: Object.assign({}, state.usersStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_USERS_SUCCESS': {
      const oldSearch = action.additionalData.search;
      const oldFilterString = action.additionalData.filterString;
      const { results, count } = action.payload;
      return {
        ...state,
        users: state.search === oldSearch && state.filterString === oldFilterString ? mergeObjectList(state.users, results) : results,
        totalUsers: count,
        search: oldSearch,
        filterString: oldFilterString,
        usersStatus: Object.assign({}, state.usersStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_USERS_FAIL': {
      return {
        ...state,
        users: [],
        usersStatus: Object.assign({}, state.usersStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_PROFILE_STAFF_REQUEST': {
      return {
        ...state,
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_PROFILE_STAFF_SUCCESS': {
      const newUser = Object.assign(
        {}, state.users.find((u) => u.profile.id === action.additionalData.userId),
      );
      newUser.profile = action.payload;
      return {
        ...state,
        users: storeUpdateObjectInArray(state.users, newUser),
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_PROFILE_STAFF_FAIL': {
      return {
        ...state,
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
