import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import { Link, withRouter } from 'react-router-dom';
import CBButtonHeader from './HeaderComponents/CBButtonHeader';
import CBProfilePictureSmall from '../shared/CBProfilePictureSmall';
import RemoteControlModal from './HeaderComponents/RemoteControlModal';
import { SERVICE_ADMIN, SERVICE_FLEET, SERVICE_PERSONAL, SERVICE_WORKSHOP } from '../../utils/utils';
import CBSelect from '../shared/CBSelect';
import { selectWorkshop as _selectWorkshop } from '../../actions/workshopActions';
import { selectOrganization as _selectOrganization } from '../../actions/organizationActions';
import CBSelectField from '../shared/form_components/CBSelectField';

class MainHeaderBar extends React.Component {
  constructor(props) {
    super(props);
    const { user, service } = props;
    this.state = {
      modalIsOpen: false,
    };
    if (user && user.workshops.length && service === SERVICE_WORKSHOP) {
      const workshopIds = user.workshops.map((ws) => ws.id);
      const selectedWorkshop = parseInt(localStorage.getItem('selectedWorkshop'));
      if (selectedWorkshop && workshopIds.includes(selectedWorkshop)) {
        this.selectWorkshop(selectedWorkshop);
      } else {
        this.selectWorkshop(user.workshops[0].id);
      }
    };
    if (user && user.organizations.length && service === SERVICE_FLEET) {
      const organizationIds = user.organizations.map((org) => org.id);
      const selectedOrganization = parseInt(localStorage.getItem('selectedOrganization'));
      if (selectedOrganization && organizationIds.includes(selectedOrganization)) {
        this.selectOrganization(selectedOrganization);
      } else {
        this.selectOrganization(user.organizations[0].id);
      }
    };
  }

  openModal = () => this.setState({ modalIsOpen: true })

  closeModal = () => this.setState({ modalIsOpen: false })

  getSelectedGarage = () => {
    const { garages, garageId } = this.props;
    if (!garageId) {
      return undefined;
    }
    return garages.find(garage => garage.id == garageId);
  };

  selectWorkshop = (value) => {
    const { selectWorkshop, history, selectedWorkshopId } = this.props;
    const oldWorkshopId = selectedWorkshopId;
    selectWorkshop(value);
    localStorage.setItem('selectedWorkshop', value);

    if (oldWorkshopId) {
      const url = history.location.pathname.replace(oldWorkshopId.toString(), value.toString());
      history.push(url);
    }
  };

  selectOrganization = (value) => {
    const { selectOrganization } = this.props;
    selectOrganization(value);
    localStorage.setItem('selectedOrganization', value);
  };

  render() {
    const { t, user, service, selectedWorkshopId, selectedOrganizationId } = this.props;
    const { modalIsOpen } = this.state;

    let settingsTo = '/settings';
    if (service === SERVICE_ADMIN) {
      settingsTo = '/staff_settings/staff_workshops';
    }
    if (service === SERVICE_WORKSHOP) {
      settingsTo = '/workshop-settings';
    }

    const selectedGarage = this.getSelectedGarage();

    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div style={getStyle(screenSize, styles, 'headerBar')}>
              <div style={getStyle(screenSize, styles, 'leftContainer')}>
                <div style={getStyle(screenSize, styles, 'logoContainer')}>
                  <Link
                    to='/'
                  >
                    <div>
                      <img
                        alt='Ovio'
                        style={getStyle(screenSize, styles, 'ovioLogo')}
                        src='/img/ovio_carbook_logo.svg'
                      />
                    </div>
                  </Link>
                </div>
                <div style={getStyle(screenSize, styles, 'orgSelectContainer')}>
                  {
                    service === SERVICE_WORKSHOP && (
                      <CBSelect value={selectedWorkshopId} onChange={(elem) => this.selectWorkshop(elem.target.value)}>
                        {
                          user.workshops.map((ws) => <option key={ws.id} value={ws.id}>{ws.name}</option>)
                        }
                      </CBSelect>
                    )
                  }
                  {
                    service === SERVICE_FLEET && (
                      <CBSelect value={selectedOrganizationId} onChange={(elem) => this.selectOrganization(elem.target.value)}>
                        {
                          user.organizations.map((org) => <option key={org.id} value={org.id}>{org.name}</option>)
                        }
                      </CBSelect>
                    )
                  }
                </div>
              </div>

              <div style={getStyle(screenSize, styles, 'menuContainer')}>
                {
                  // Hidden because the feature is ugly and broken atm 2024-02-22
                  false && service === SERVICE_WORKSHOP && user.workshops.length > 0 && (
                    <React.Fragment>
                      <CBButtonHeader
                        containerStyle={{
                          backgroundImage: 'linear-gradient(0deg, rgb(0, 149, 218) 3%, rgb(0, 105, 171) 100%)',
                          backgroundPosition: 'center center',
                        }}
                        text={t('Tiedotteet')}
                        icon='/img/icon_announcements.svg'
                        to='/announcements/'
                      />
                    </React.Fragment>
                  )
                }
                {
                  service === SERVICE_PERSONAL && (
                    <CBButtonHeader
                      containerStyle={{
                        backgroundImage: 'linear-gradient(135deg, #00f600 3%, #07d107 100% )',
                        backgroundPosition: 'center center',
                      }}
                      text={selectedGarage ? selectedGarage.name : t('garageButton')}
                      icon='/img/icon_carage_c.svg'
                      to={selectedGarage ? `/organization_garage/${selectedGarage.id}` : '/garage'}
                    />
                  )
                }
                {
                  !(service === SERVICE_WORKSHOP && user.workshops.length === 0) && (
                    <CBButtonHeader
                      containerStyle={{
                        backgroundImage: 'linear-gradient(-45deg, #464646 1%, #a1a1a1 100% )',
                        backgroundPosition: 'center center',
                      }}
                      text={t('settingsButton')}
                      icon='/img/icon_settings.svg'
                      to={settingsTo}
                    />
                  )
                }
                {service === SERVICE_PERSONAL && <CBProfilePictureSmall />}
                {service === SERVICE_ADMIN && user.is_staff && (
                  <React.Fragment>
                    <div
                      style={getStyle(
                        screenSize,
                        styles,
                        'remoteControlButton',
                      )}
                      onClick={this.openModal}
                    >
                      <img
                        src='/img/icon_remote_control.svg'
                        alt={t('remoteControlAltText')}
                        style={getStyle(
                          screenSize,
                          styles,
                          'remoteControlIcon',
                        )}
                      />
                    </div>
                    <RemoteControlModal
                      modalIsOpen={modalIsOpen}
                      closeModal={this.closeModal}
                      connectionActive={service === SERVICE_ADMIN && selectedWorkshopId}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    orgSelectContainer: {
      marginLeft: 16,
    },
    leftContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    ovioLogo: {
      height: 30,
      fill: '#000',
    },

    headerBar: {
      width: '100%',
      height: 50,
      paddingLeft: 175,
      paddingRight: 175,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      top: 0,
      backgroundColor: '#0095DA',
      zIndex: 100,
    },
    remoteControlButton: {
      display: 'flex',
      backgroundColor: '#00f600',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      cursor: 'pointer',
    },
    remoteControlIcon: {
      width: 32,
      height: 32,
    },
  },
  medium: {
    logoContainer: {
      marginLeft: 20,
    },
    menuContainer: {
      marginRight: 0,
    },
    headerBar: {
      paddingLeft: '0%',
      paddingRight: '0%',
    },
  },
  small: {
    menuContainer: {
      marginRight: 0,
    },
    logoContainer: {
      marginLeft: 10,
      flexDirection: 'row',
      alignSelf: 'center',
      alignItems: 'baseline',
    },
    ovioLogo: {
      width: 'auto',
      height: 18,
      fill: '#000',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    garageId: state.garage.selectedGarage,
    garages: state.garage.garages,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    selectWorkshop: _selectWorkshop,
    selectOrganization: _selectOrganization,
  },
)(translate('Header')(withRouter(MainHeaderBar)));
