import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import {
  fetchPriceRules as _fetchPriceRules,
  createPriceRule as _createPriceRule,
  updatePriceRule as _updatePriceRule,
  deletePriceRule as _deletePriceRule,
} from '../../../actions/priceRuleActions';
import _ from 'lodash';
import PriceRuleRow from './PriceRuleRow';

class StaffSettingsPricing extends React.Component {
  componentDidMount () {
    const { fetchPriceRules } = this.props;
    fetchPriceRules();
  }

  render() {
    const {
      t,
      createPriceRule,
      updatePriceRule,
      deletePriceRule,
      priceRules,
    } = this.props;

    const sortedPriceRules = _.reverse(_.sortBy(priceRules, 'valid_to_date'));

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBHeaderTextWithHelp text={t('staffSettingsPricingHeading')} />
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <p>
                {t('staffSettingsPricingDescription')}
              </p>
            </div>
            <React.Fragment>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleDescriptionLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleValidFromLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleValidToLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleWorkshopPriceLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleOrganizationPriceLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('priceRuleVehicleClassesLabel')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedPriceRules.map((priceRule) =>
                      (
                        <PriceRuleRow
                          key={priceRule.id}
                          priceRule={priceRule}
                          createPriceRule={createPriceRule}
                          updatePriceRule={updatePriceRule}
                          deletePriceRule={deletePriceRule}
                        />
                      ))
                  }
                  <PriceRuleRow
                    isNew
                    priceRule={{
                      description: '',
                      valid_from_date: null,
                      valid_to_date: null,
                      workshop_price: 0.00,
                      organization_price: 0.00,
                      vehicle_classes: [],
                    }}
                    createPriceRule={createPriceRule}
                    updatePriceRule={updatePriceRule}
                    deletePriceRule={deletePriceRule}
                  />
                </tbody>
              </table>
            </React.Fragment>
          </div>
        )}
      </CBMediaQuery>
    );
  }

};

const styles = {
  default: {
    container: {
      padding: 16,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 8,
      paddingBottom: 8,
    },
    table: {
      tableLayout: 'auto',
    },
    sectionContainer: {
      marginBottom: 16,
    },
  },
  medium: {
    container: {
      padding: 0,
    },
  },
  small: {
    container: {
      padding: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    priceRules: state.priceRule.priceRules,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchPriceRules: _fetchPriceRules,
    createPriceRule: _createPriceRule,
    updatePriceRule: _updatePriceRule,
    deletePriceRule: _deletePriceRule,
  },
)(translate('SettingsView')(StaffSettingsPricing));
