import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import { createVehicleMaintenanceMessage as _createVehicleMaintenanceMessage } from '../../../actions/vehicleActions';
import { fetchSMSCounts as _fetchSMSCounts } from '../../../actions/workshopActions';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import BuySMSModal from './BuySMSModal';

const MESSAGE_MAX_LENGTH = 160;

class VehicleDetailsMaintenanceMessageForm extends Component {
  constructor(props) {
    super(props);

    const workshop = props.user.workshops.find((ws) => ws.id == props.selectedWorkshopId);

    this.state = {
      modalIsOpen: false,
      confirmationVisible: false,
      formData: {
        message: workshop ? workshop.default_message : '',
        visible_in_ovio: true,
        send_email: true,
        send_sms: false,
        maintenance: null,
      },
      formValid: {
        message: true,
      },
    };
  }

  componentWillMount = () => {
    const { fetchSMSCounts, user, selectedWorkshopId } = this.props;
    fetchSMSCounts(selectedWorkshopId);
  };

  openModal = () => this.setState({ modalIsOpen: true });

  closeModal = success => {
    this.setState(prevState => ({
      modalIsOpen: false,
      formData: { ...prevState.formData, send_sms: success },
    }));
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  hideConfirmation = () => this.setState({ confirmationVisible: false });

  showConfirmation = () => this.setState({ confirmationVisible: true });

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  toggleBoolean = key => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [key]: !prevState.formData[key],
      },
    }));
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const formData = Object.assign({}, this.state.formData);
    formData.maintenance = this.props.match.params.maintenanceId;
    formData.sender = this.props.user.id;
    this.props.createVehicleMaintenanceMessage(formData);
    this.setState({ confirmationVisible: false });
  };

  getRequiredSMSCount = () => {
    // SMS messages longer than 160 are sent in 153 character segments
    const { message } = this.state.formData;
    return message.length < 161 ? 1 : Math.ceil(message.length / 153);
  };

  render() {
    const { t, smsAvailable, smsMax, maintenance, user, selectedWorkshopId } = this.props;
    if (!maintenance) {
      return false;
    }
    if (maintenance.source !== 'carbook' || maintenance.workshop.id !== selectedWorkshopId) {
      return false;
    }
    const { formData, modalIsOpen, confirmationVisible } = this.state;
    const messageLength = formData.message.length;
    const requiredSMSCount = this.getRequiredSMSCount();
    const smsExtraText =
      requiredSMSCount < 2
        ? ''
        : `, ${t('requiredSmsCountLabel', {
            count: requiredSMSCount,
          })}`;
    const smsLabelText = `${t('sms')} (${t(
      'available',
    )} ${smsAvailable}/${smsMax}${smsExtraText})`;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'messageContainer')}>
            <div style={getStyle(screenSize, styles, 'header')}>
              <p
                className='font-titillium-web-light'
                style={getStyle(screenSize, styles, 'title')}
              >
                {t('sendMessage')}
              </p>
              <p
                className='font-titillium-web'
                style={getStyle(screenSize, styles, 'messageLength')}
              >
                {messageLength}/{MESSAGE_MAX_LENGTH} {t('characters')}
              </p>
            </div>
            <CBTextAreaField
              name='message'
              inputStyle={getStyle(screenSize, styles, 'messageInput')}
              value={formData.message}
              onChange={this.updateFormState}
              isValidCallback={this.setIsValid}
            />
            <CBLabelAndCheckbox
              labelText={t('email')}
              checked={formData.send_email}
              onChange={() => this.toggleBoolean('send_email')}
            />
            <CBLabelAndCheckbox
              labelText={smsLabelText}
              checked={formData.send_sms}
              onChange={
                smsAvailable < requiredSMSCount && !formData.sms
                  ? this.openModal
                  : () => this.toggleBoolean('send_sms')
              }
            />
            <div style={getStyle(screenSize, styles, 'sendRow')}>
              <div
                className='opacity-and-border-on-hover'
                style={getStyle(screenSize, styles, 'box')}
                onClick={this.showConfirmation}
              >
                <img
                  style={getStyle(screenSize, styles, 'buttonIcon')}
                  alt='edit'
                  src='/img/icon_arrow_right_2.svg'
                />
                <div style={getStyle(screenSize, styles, 'boxText')}>
                  {`${t('sendButtonLabel')} `}
                </div>
              </div>
              {confirmationVisible && (
                <div
                  style={getStyle(screenSize, styles, 'confirmationContainer')}
                >
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={this.submitForm}
                    src='/img/round_check_icon_green.svg'
                    alt=''
                  />
                  <div
                    className='font-titillium-web-light'
                    style={getStyle(screenSize, styles, 'confirmationText')}
                  >
                    {t('maintenanceMessageConfirmationQuestion')}
                  </div>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={this.hideConfirmation}
                    src='/img/round_x_icon_red.svg'
                    alt=''
                  />
                </div>
              )}
            </div>
            <BuySMSModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    messageContainer: {
      padding: 20,
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      color: 'white',
    },
    title: {
      fontSize: 32,
    },
    messageLength: {
      fontSize: 14,
      textAlign: 'right',
      textDecoration: 'none solid rgb(255, 255, 255)',
    },
    messageInput: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      borderRadius: 5,
      borderWidth: 0,
      width: '100%',
      height: 146,
      resize: 'none',
      color: 'white',
      padding: 20,
      fontFamily: 'TitilliumWeb-ExtraLight',
    },
    sendRow: {
      display: 'flex',
    },
    box: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.5)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
      marginTop: 16,
      marginBottom: 16,
      'hover:': {
        borderColor: 'white',
      },
    },
    boxText: {
      color: 'white',
      fontSize: 14,
      textDecoration: 'none solid rgb(255, 255, 255)',
      marginLeft: 10,
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
    },
    confirmationContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 8,
    },
    confirmationText: {
      color: 'white',
      marginLeft: 8,
      marginRight: 8,
    },
    errorText: {
      color: 'red',
      marginLeft: 8,
      marginRight: 8,
    },
  },
  medium: {},
};

function mapStateToProps(state, props) {
  return {
    user: state.authUser.user,
    maintenance: state.vehicle.vehicle.maintenances.find(m => m.id === parseInt(props.match.params.maintenanceId, 10)),
    maintenanceMessageStatus: state.vehicle.maintenanceMessageStatus,
    smsAvailable: state.workshop.smsAvailable,
    smsMax: state.workshop.smsMax,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleMaintenanceMessage: _createVehicleMaintenanceMessage,
    fetchSMSCounts: _fetchSMSCounts,
  },
)(translate('VehicleDetailsView')(VehicleDetailsMaintenanceMessageForm));
