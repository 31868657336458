import React, { useRef } from 'react';
import { translate } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { formatDate, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, styleMerge, truncateString, TYPE_ORGANIZATION_GARAGE, TYPE_WORKSHOP_ALL } from '../../../utils/utils';
import OptionsButton from '../../GarageView/GarageViewComponents/OptionsComponents/OptionsButton';
import EditGarageVehicleModal from './EditGarageVehicleModal';
import CBPromptModal from '../../shared/CBPromptModal';
import { withRouter } from 'react-router';
import AttachVehicleModal from './AttachVehicleModal';
import VehicleListTd from './VehicleListTd';
import CBCheckbox from '../../shared/form_components/CBCheckbox';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import SelectCheckListItemsModal from './SelectCheckListItemsModal';

class VehicleListItem extends React.Component {
  state = {
    editModalIsOpen: false,
    deleteModalIsOpen: false,
    attachModalIsOpen: false,
    endCareServiceModalIsOpen: false,
    checkListModalIsOpen: false,
  }

  constructor(props) {
    super(props);
    this.settingsMenu = React.createRef();
  }

  componentDidMount() {
  }

  closeModal = () => {
    this.setState({
      editModalIsOpen: false,
    });
  };

  closeDeletePromptModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  closeCheckListModal = () => {
    const {
      refetchVehicles,
    } = this.props;

    this.setState({
      checkListModalIsOpen: false,
    });
    refetchVehicles();
  };

  closeAttachModal = () => {
    this.setState({
      attachModalIsOpen: false,
    });
  };

  closeEndCareServiceModal = () => {
    this.setState({
      endCareServiceModalIsOpen: false,
    });
  };

  checkBoxChanged = () => {
    const {
      selected,
      select,
      unselect,
      vehicle,
    } = this.props;

    if (selected) {
      unselect(vehicle.id);
    } else {
      select(vehicle.id);
    }
  };

  getVehicleOptions = (vehicle) => {
    const { service, listType } = this.props;

    const urlPrefix = listType === TYPE_WORKSHOP_ALL ? 'workshop-vehicle' : 'vehicle';
    const options = [
      {
        label: 'Avaa',
        icon: 'pi pi-info',
        url: `/${urlPrefix}/${vehicle.id}/`,
      },
    ];

    if (listType !== TYPE_WORKSHOP_ALL) {
      options.push({
        label: 'Muokkaa tietoja',
        icon: 'pi pi-pencil',
        command: () => {
          this.setState({
            editModalIsOpen: true,
          });
        },
      });
    }

    if (listType === TYPE_ORGANIZATION_GARAGE) {
      options.push({
        label: 'Liitä ajoneuvoon',
        icon: 'pi pi-pencil',
        command: () => {
          this.setState({
            attachModalIsOpen: true,
          });
        },
      });
    }

    if (service === SERVICE_FLEET || (service === SERVICE_WORKSHOP && listType !== TYPE_WORKSHOP_ALL)) {
      options.push({
        label: 'Tarkastettavat kohteet',
        icon: 'pi pi-calendar',
        command: () => {
          this.setState({
            checkListModalIsOpen: true,
          });
        },
      });
    }

    if (vehicle.care_service_workshop_name) {
      options.push({
        label: 'Lopeta huolenpitopalvelu',
        icon: 'pi pi-trash',
        command: () => {
          this.setState({
            endCareServiceModalIsOpen: true,
          });
        },
      });
    }

    if (service === SERVICE_FLEET && vehicle.can_delete) {
      options.push({
        label: 'Poista',
        icon: 'pi pi-trash',
        command: () => {
          this.setState({
            deleteModalIsOpen: true,
          });
        },
      });
    }

    return options;
  };

  navigateToVehicle = (vehicleId) => {
    const { history, vehicle, listType } = this.props;
    const urlPrefix = listType === TYPE_WORKSHOP_ALL ? 'workshop-vehicle' : 'vehicle';
    history.push(`/${urlPrefix}/${vehicle.id}/`);
  };

  navigateToVehicleMessages = (vehicleId) => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/messages`);
  };

  render() {
    const {
      vehicle,
      t,
      selectedFields,
      garage,
      removeVehicleFromGarage,
      selected,
      endCareService,
      refetchVehicles,
      listType
    } = this.props;
    const { editModalIsOpen, deleteModalIsOpen, attachModalIsOpen, endCareServiceModalIsOpen, checkListModalIsOpen } = this.state;

    const imageUrl = vehicle.image ? vehicle.image.image : 'none';

    const imageStyle = {
      backgroundImage: `url("${imageUrl}")`,
    };

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'vehicleRow')}>
              <td style={getStyle(screenSize, styles, 'tableRegNumTd')}>
                <CBLabelAndCheckbox
                  additionalStyles={{ justifyContent: 'center' }}
                  checked={selected}
                  onChange={this.checkBoxChanged}
                />
              </td>
              {
                selectedFields.includes('image') && listType != TYPE_WORKSHOP_ALL && (
                  <td onClick={this.navigateToVehicle} style={getStyle(screenSize, styles, 'vehicleImageColumn')}>
                    <div style={styleMerge(getStyle(screenSize, styles, 'imgPlaceholder'), imageStyle)}></div>
                  </td>
                )
              }

              <VehicleListTd
                onClick={this.navigateToVehicle}
                textPrimary={vehicle.registration_number}
                textSecondary={truncateString([vehicle.manufacturer, vehicle.model_name].join(' '), 35)}
                additionalStylesPrimaryText={{ fontSize: 24 }}
              />
              {
                selectedFields.includes('vehicle_class') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.vehicle_class ? vehicle.vehicle_class : ''}
                  />
                )
              }
              {
                selectedFields.includes('organization') && listType != TYPE_WORKSHOP_ALL && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.organization}
                    textSecondary={vehicle.garage}
                  />
                )
              }
              {
                selectedFields.includes('care_service_workshop_name') && listType != TYPE_WORKSHOP_ALL && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.care_service_workshop_name}
                  />
                )
              }
              {
                selectedFields.includes('employee') && listType != TYPE_WORKSHOP_ALL && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.organization_vehicle_status && vehicle.organization_vehicle_status.employee}
                    textSecondary={vehicle.organization_vehicle_status && vehicle.organization_vehicle_status.business_unit}
                  />
                )
              }
              {
                selectedFields.includes('unread_message_count') && listType != TYPE_WORKSHOP_ALL && (
                  <td onClick={this.navigateToVehicleMessages} style={styleMerge(getStyle(screenSize, styles, 'tableValueTd'), getStyle(screenSize, styles, 'alignCenter'))}>
                    {
                      vehicle.unread_message_count && (
                        vehicle.unread_message_count > 0
                          ? <img src='/img/icon_11.svg' alt='Uusia viestejä' style={getStyle(screenSize, styles, 'infoIcon')} />
                          : 'Ei'
                      )
                    }
                  </td>
                )
              }
              {
                selectedFields.includes('attached_vehicles') && listType != TYPE_WORKSHOP_ALL && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.children && vehicle.children.map((child) => child.registration_number).join(', ')}
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }
              {
                selectedFields.includes('last_maintenance_date') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={formatDate(vehicle.last_maintenance_date)}
                    textSecondary={vehicle.last_maintenance_km ? `${vehicle.last_maintenance_km} km` : ''}
                  />
                )
              }
              {
                selectedFields.includes('maintenance_count') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.maintenance_count != null ? vehicle.maintenance_count : ''}
                  />
                )
              }
              {
                selectedFields.includes('inspection_interval_end') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.inspection_interval_end && formatDate(vehicle.inspection_interval_end)}
                    textSecondary={vehicle.last_inspection_date && formatDate(vehicle.last_inspection_date)}
                  />
                )
              }
              {
                selectedFields.includes('next_check_date') && listType != TYPE_WORKSHOP_ALL && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={vehicle.next_check
                      && formatDate(vehicle.next_check.date)}
                    textSecondary={vehicle.next_check
                      && vehicle.next_check.name}
                  />
                )
              }

              {
                selectedFields.includes('traficom_sync_date') && (
                  <td onClick={this.navigateToVehicle} style={getStyle(screenSize, styles, 'tableValueTd')}>
                    {vehicle.traficom_sync_date && formatDate(vehicle.traficom_sync_date)}
                  </td>
                )
              }

              <td style={getStyle(screenSize, styles, 'tableOptionsTd')}>
                <Menu model={this.getVehicleOptions(vehicle)} popup ref={this.settingsMenu} />
                <div
                  style={getStyle(screenSize, styles, 'container')}
                  onClick={(e) => {this.settingsMenu.current.toggle(e)}}
                >
                  <img
                    alt='Options'
                    className="opacity-and-border-on-hover"
                    style={getStyle(screenSize, styles, 'optionsIcon')}
                    src='/img/icon_options.svg'
                  />
                </div>
                {
                  editModalIsOpen && (
                    <EditGarageVehicleModal
                      modalIsOpen={editModalIsOpen}
                      closeModal={this.closeModal}
                      garageVehicleStatus={vehicle.organization_vehicle_status}
                      vehicleId={vehicle.id}
                    />
                  )
                }
                {
                  attachModalIsOpen && (
                    <AttachVehicleModal
                      modalIsOpen={attachModalIsOpen}
                      closeModal={this.closeAttachModal}
                      vehicle={vehicle}
                      garage={garage}
                    />
                  )
                }
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={this.closeDeletePromptModal}
              afterOpenModal={() => {}}
              title={t('deleteFromGaragePromptTitle')}
              text={t('deleteFromGaragePromptText')}
              buttonText={t('deleteFromGaragePromptButton')}
              performAction={() => removeVehicleFromGarage(vehicle.id, vehicle.garage_id)}
            />

            <CBPromptModal
              modalIsOpen={endCareServiceModalIsOpen}
              closeModal={this.closeEndCareServiceModal}
              afterOpenModal={() => {}}
              title={t('endCareServiceTitle')}
              text={t('endCareServiceText')}
              buttonText={t('endCareServiceButton')}
              performAction={() => {
                endCareService(vehicle);
                this.closeEndCareServiceModal();
              }}
            />

            {
              checkListModalIsOpen && (
                <SelectCheckListItemsModal
                  modalIsOpen={checkListModalIsOpen}
                  closeModal={this.closeCheckListModal}
                  afterOpenModal={() => {}}
                  vehicle={vehicle}
                />
              )
            }

          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoIcon: {
      height: 32,
      width: 32,
    },
    alignCenter: {
      textAlign: 'center',
    },
    vehicleRow: {
      height: '60px',
      width: '100%',
      marginBottom: 2,

    },
    imgPlaceholder: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: 100,
      height: 60,
    },
    vehicleImageColumn: {
      width: 100,
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableValueTd: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 18,
      color: '#FFFFFF',
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    optionsIcon: {
      cursor: 'pointer',
    },
    tableRegNumTd: {
      fontFamily: 'TitilliumWeb-Bold',
      fontSize: 18,
      color: '#FFFFFF',
      //position: 'sticky',
      left: 0,
      padding: '8px 16px 8px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableOptionsTd: {
      //position: 'sticky',
      right: 0,
      padding: '0 16px 0 16px',
      zIndex: 0,
    },
  },
  medium: {
  },
  small: {
  },
};

export default translate('VehicleListItem')(withRouter(VehicleListItem));
