import React from 'react';
import VehicleDetailsTopInfo from './WSVehicleDetailsTopInfo';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle } from '../../../../utils/utils';
import BackButton from '../../../shared/BackButton';
import WSHeaderData from './WSHeaderData';

class VehicleDetailsTop extends React.Component {
  render() {
    const { vehicle, user, navigateBack } = this.props;
    if (vehicle) {
      return (
        <React.Fragment>
          <CBMediaQuery>
            {screenSize => (
              <div style={getStyle(screenSize, styles, 'topContainer')}>
                <div style={getStyle(screenSize, styles, 'headerBackground')} />
                <div
                  style={getStyle(screenSize, styles, 'backButtonContainer')}
                >
                  <BackButton navigateBack={navigateBack} />
                </div>
                <div style={getStyle(screenSize, styles, 'infoContainer')}>
                  <VehicleDetailsTopInfo
                    screenSize={screenSize}
                    vehicle={vehicle}
                  />
                  <WSHeaderData vehicle={vehicle} user={user} />
                </div>
              </div>
            )}
          </CBMediaQuery>
        </React.Fragment>
      );
    } else {
      return (
        <div style={styles.topContainer}>
          <div style={styles.imageContainer} />
          <div style={styles.infoContainer} />
          <div style={styles.reminderContainer} />
        </div>
      );
    }
  }
}

const styles = {
  default: {
    smallInfoText: {
      color: '#FFF',
      fontSize: 12,
    },
    mainLabel: {
      fontSize: 32,
      color: '#ffffff',
      fontWeight: 300,
    },
    subLabel: {
      fontSize: 20,
      color: '#ffffff',
      fontWeight: 200,
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: 85,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 16,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    infoTopContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    backButtonContainer: {
      marginLeft: 0,
    },
    headerBackground: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 85,
      background: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      zIndex: -1,
      boxShadow: '0px 1px 10px 0px #000000',
    },
  },
  medium: {
    topContainer: {
      height: 110,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 0,
      paddingBottom: 0,
    },
    infoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: 80,
    },
    backButtonContainer: {
      marginTop: 10,
      marginRight: 20,
      position: 'absolute',
      top: 60,
    },
  },
  small: {
    topContainer: {
      display: 'flex',
      height: 'auto',
      flexDirection: 'row',
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      height: 'auto',
    },
    backButtonContainer: {
      marginTop: 10,
      marginRight: 20,
      top: 70,
    },
    infoContainer: {
      flexWrap: 'wrap',
      marginLeft: 50,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
};

export default VehicleDetailsTop;
