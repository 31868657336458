import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import WSSettingsProfileInfoForm from './WSSettingsProfileInfoForm';
import { patchWorkshopInfo as _patchWorkshopInfo } from '../../../actions/workshopActions';
import { getStyle } from '../../../utils/utils';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import CBButtonSettings from '../../shared/CBButtonSettings';

class WSSettingsProfile extends React.Component {
  constructor(props) {
    super(props);

    const { selectedWorkshopId, workshops} = props;

    this.state = {
      formData: {
        default_message: workshops && workshops.length
          ? workshops.find((ws) => ws.id == selectedWorkshopId).default_message
          : ''
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, workshops} = this.props;

    const default_message = workshops && workshops.length
      ? workshops.find((ws) => ws.id == selectedWorkshopId).default_message
      : '';

    if (selectedWorkshopId != prevProps.selectedWorkshopId || (!this.state.formData.default_message && default_message)) {
      this.setState({
        formData: {
          default_message: default_message,
        },
      });
    }
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { patchWorkshopInfo, user, selectedWorkshopId } = this.props;
    const { formData } = this.state;
    patchWorkshopInfo(formData, selectedWorkshopId);
  };

  render() {
    const { user, t, workshops } = this.props;
    const { formData } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsProfileContainer')}>
            <div
              style={getStyle(
                screenSize,
                styles,
                'personalInformationContainer',
              )}
            >
              <CBHeaderTextWithHelp text={t('titleLabel')} />
              {
                !!workshops.length && <WSSettingsProfileInfoForm />
              }
            </div>
            <div style={getStyle(screenSize, styles, 'otherSettingsContainer')}>
              <div
                style={getStyle(screenSize, styles, 'defaultMessageContainer')}
              >
                <p style={getStyle(screenSize, styles, 'defaultMessageTitle')}>
                  {t('defaultMessageTitle')}
                </p>
                <p style={getStyle(screenSize, styles, 'defaultMessageInfo')}>
                  {t('defaultMessageInfo')}
                </p>
                <CBTextAreaField
                  name='default_message'
                  inputStyle={getStyle(
                    screenSize,
                    styles,
                    'defaultMessageInput',
                  )}
                  value={formData.default_message}
                  onChange={this.updateFormState}
                />
                <div
                  style={getStyle(screenSize, styles, 'defaultMessageSaveRow')}
                >
                  <div
                    style={getStyle(
                      screenSize,
                      styles,
                      'defaultMessageSaveButtonContainer',
                    )}
                  >
                    <CBButtonSettings
                      text={t('saveLabel')}
                      onClick={this.submitForm}
                    />
                  </div>
                  <p
                    style={getStyle(
                      screenSize,
                      styles,
                      'defaultMessageLengthIndicator',
                    )}
                  >
                    {formData.default_message.length}/160 merkkiä
                  </p>
                </div>
              </div>
              <div
                style={getStyle(screenSize, styles, 'removeAccountContainer')}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

// TODO: remove unused styles
const styles = {
  default: {
    settingsProfileContainer: {
      display: 'flex',
      paddingBottom: 50,
    },
    tokenText: {
      fontWeight: 'bold',
    },
    personalInformationContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '40%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    },
    otherSettingsContainer: {
      width: '40%',
      paddingRight: 20,
    },
    removeAccountContainer: {
      paddingLeft: 40,
      paddingTop: 20,
    },
    tokenContainer: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      paddingLeft: 40,
      paddingBottom: 20,
      paddingTop: 20,
    },
    defaultMessageContainer: {
      padding: 45,
    },
    defaultMessageTitle: {
      fontSize: 22,
      fontFamily: 'TitilliumWeb-ExtraLight',
    },
    defaultMessageInfo: {
      fontSize: 12,
      fontFamily: 'TitilliumWeb-Regular',
    },
    defaultMessageInput: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius: 5,
      borderWidth: 1,
      width: '100%',
      height: 180,
      resize: 'none',
      color: 'white',
      padding: 15,
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 14,
    },
    defaultMessageSaveRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    defaultMessageSaveButtonContainer: {
      width: 150,
    },
    defaultMessageLengthIndicator: {
      textAlign: 'right',
      fontSize: 12,
    },
  },
  medium: {
    otherSettingsContainer: {
      width: '50%',
      paddingTop: 25,
      paddingRight: 0,
      paddingLeft: 15,
    },
    personalInformationContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 25,
      width: '50%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    },
    defaultMessageContainer: {
      padding: '0, 15, 0, 15',
    },
  },
  small: {
    settingsProfileContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    personalInformationContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
    },
    otherSettingsContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
    facebookContainer: {
      paddingLeft: 0,
    },
    reminderContainer: {
      paddingLeft: 0,
    },
    removeAccountContainer: {
      paddingLeft: 0,
    },
    tokenContainer: {
      paddingLeft: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    workshops: state.workshop.workshops,
  };
}

export default connect(
  mapStateToProps,
  {
    patchWorkshopInfo: _patchWorkshopInfo,
  },
)(translate('WSSettingsView')(WSSettingsProfile));
