import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle, TYPE_WORKSHOP_ALL } from '../../utils/utils';
import {
  fetchWSVehicles as _fetchWSVehicles,
  fetchWSVehiclesNextPage as _fetchWSVehiclesNextPage,
  clearCurrentVehicle as _clearCurrentVehicle,
} from '../../actions/vehicleActions';
import HomeWSUser from '../HomeView/HomeWSUser';
import VehicleListView from '../OrganizationGarageView/OrganizationGarageComponents/VehicleListView';

class MaintenancesAndVehiclesLayoutView extends React.Component {
  componentDidMount = () => {
    const {
      fetchWSVehicles, selectedWorkshopId
    } = this.props;
    fetchWSVehicles(selectedWorkshopId, {page: 1, ordering: '-last_maintenance_date'});
  };

  render() {
    const {
      history, t, wsVehicles, fetchWSVehicles, selectedWorkshopId, pagination
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            {
              false && <HomeWSUser />
            }
            {
              true && <VehicleListView
                vehicles={wsVehicles}
                fetchVehicles={(params) => { fetchWSVehicles(selectedWorkshopId, params); }}
                listType={TYPE_WORKSHOP_ALL}
                usePagination={true}
                pagination={pagination}
                ordering='-last_maintenance_date'
              />
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    selectedWorkshopId: state.workshop.selectedWorkshop,
    wsVehicles: state.vehicle.wsVehicles,
    pagination: state.vehicle.wsVehiclePagination,
    searchRequest: state.search,
    authUserRequest: state.authUser,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWSVehicles: _fetchWSVehicles,
  },
)(translate('MaintenancesAndVehiclesLayoutView')(MaintenancesAndVehiclesLayoutView));
