import { priceRuleState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = priceRuleState, action) {
  switch (action.type) {
    case 'CREATE_PRICE_RULE_REQUEST': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_PRICE_RULE_SUCCESS': {
      return {
        ...state,
        priceRules: storeInsertItem(state.priceRules, action.payload),
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_PRICE_RULE_FAIL': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_PRICE_RULE_REQUEST': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_PRICE_RULE_SUCCESS': {
      return {
        ...state,
        priceRules: storeUpdateObjectInArray(state.priceRules, action.payload),
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_PRICE_RULE_FAIL': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_PRICE_RULE_REQUEST': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_PRICE_RULE_SUCCESS': {
      return {
        ...state,
        priceRules: storeRemoveItem(state.priceRules, action.additionalData.itemId),
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_PRICE_RULE_FAIL': {
      return {
        ...state,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_PRICE_RULE_REQUEST': {
      return {
        ...state,
        priceRules: [],
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_PRICE_RULE_SUCCESS': {
      return {
        ...state,
        priceRules: action.payload,
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_PRICE_RULE_FAIL': {
      return {
        ...state,
        priceRules: [],
        priceRuleStatus: Object.assign({}, state.priceRuleStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
