import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import { fetchWorkshops as _fetchWorkshops, selectWorkshop as _selectWorkshop } from '../../../actions/workshopActions';
import {
  fetchAdminWorkshop as _fetchAdminWorkshop,
  clearOwnWorkshop as _clearOwnWorkshop,
} from '../../../actions/authUserActions';
import { clearWSVehicles as _clearWSVehicles } from '../../../actions/vehicleActions';

class RemoteControlModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        search: '',
      },
      selectedWorkshop: null,
    };
  }

  componentDidMount = () => {
    this.props.fetchWorkshops({ordering: 'name'});
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData, selectedWorkshop: null });
  };

  selectWorkshop = ws => {
    this.setState({ selectedWorkshop: ws.id, formData: { search: ws.name } });
  };

  getIsValid = () => {
    const { connectionActive } = this.props;
    const { selectedWorkshop } = this.state;
    return connectionActive || selectedWorkshop !== null;
  };

  connectToWorkshop = () => {
    const { fetchAdminWorkshop, selectWorkshop, closeModal } = this.props;
    const { selectedWorkshop } = this.state;
    //fetchAdminWorkshop(selectedWorkshop);
    selectWorkshop(selectedWorkshop);
    closeModal();
  };

  disconnect = () => {
    const { selectWorkshop, clearWSVehicles } = this.props;
    clearWSVehicles();
    selectWorkshop(undefined);
  };

  render() {
    const { formData, selectedWorkshop } = this.state;
    const {
      modalIsOpen,
      closeModal,
      t,
      workshops,
      connectionActive,
    } = this.props;
    let filteredWorkshops;
    if (formData.search !== '') {
      filteredWorkshops = workshops.filter(ws =>
        ws.name.toLowerCase().includes(formData.search.toLowerCase()),
      );
    } else {
      filteredWorkshops = [];
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('remoteControlModalTitle')}
              </p>
              {!connectionActive && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'fieldsRow')}>
                    <CBInputField
                      name='search'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.search}
                      placeholder={t('workshopNameInputPlaceholder')}
                      onChange={this.updateFormState}
                      isValidCallback={() => null}
                      labelText={t('workshopNameInputLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                      autoComplete={false}
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'workshopList')}>
                    {!selectedWorkshop &&
                      filteredWorkshops.map(ws => (
                        <div
                          key={ws.id}
                          style={getStyle(
                            screenSize,
                            styles,
                            'workshopListItem',
                          )}
                          onClick={() => this.selectWorkshop(ws)}
                        >
                          {ws.name}
                        </div>
                      ))}
                    {!selectedWorkshop &&
                      formData.search !== '' &&
                      !connectionActive &&
                      filteredWorkshops.length === 0 && (
                        <div style={getStyle(screenSize, styles, 'errorText')}>
                          {t('noWorkshopsFound')}
                        </div>
                      )}
                  </div>
                </React.Fragment>
              )}
              <CBButtonSubmit
                disabled={!this.getIsValid()}
                onClick={
                  connectionActive ? this.disconnect : this.connectToWorkshop
                }
                text={
                  connectionActive
                    ? t('disconnectRemoteControlButton')
                    : t('connectRemoteControlButton')
                }
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '100%',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      textAlign: 'center',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    inputCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    workshopList: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '30px 0',
      marginBottom: 30,
      borderStyle: 'solid',
      borderColor: 'rgba(102, 102, 102, 0.3)',
      borderWidth: '0 0 1px 0',
      alignItems: 'center',
    },
    workshopListItem: {
      fontFamily: 'TitilliumWeb-SemiBold',
      cursor: 'pointer',
      marginBottom: 5,
    },
    errorText: {
      fontFamily: 'TitilliumWeb-SemiBold',
      color: 'red',
    },
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
      cursor: 'pointer',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'column',
      width: 250,
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 100,
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    workshops: state.workshop.workshops,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWorkshops: _fetchWorkshops,
    fetchAdminWorkshop: _fetchAdminWorkshop,
    clearOwnWorkshop: _clearOwnWorkshop,
    selectWorkshop: _selectWorkshop,
    clearWSVehicles: _clearWSVehicles,
  },
)(translate('Header')(RemoteControlModal));
