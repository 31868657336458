import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchPriceRules(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/price_rule/`,
      types: [
        'FETCH_PRICE_RULE_REQUEST',
        'FETCH_PRICE_RULE_SUCCESS',
        'FETCH_PRICE_RULE_FAIL',
      ],
      params,
    },
  };
}

export function createPriceRule(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/price_rule/`,
      types: [
        'CREATE_PRICE_RULE_REQUEST',
        'CREATE_PRICE_RULE_SUCCESS',
        'CREATE_PRICE_RULE_FAIL',
      ],
      data,
    },
  };
}

export function updatePriceRule(priceRuleId, data, params = {}) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/price_rule/${priceRuleId}/`,
      types: [
        'UPDATE_PRICE_RULE_REQUEST',
        'UPDATE_PRICE_RULE_SUCCESS',
        'UPDATE_PRICE_RULE_FAIL',
      ],
      data,
      params,
    },
  };
}

export function deletePriceRule(priceRuleId, params = {}) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/price_rule/${priceRuleId}/`,
      types: [
        'DELETE_PRICE_RULE_REQUEST',
        'DELETE_PRICE_RULE_SUCCESS',
        'DELETE_PRICE_RULE_FAIL',
      ],
      additionalData: {
        itemId: priceRuleId,
      },
      params,
    },
  };
}
