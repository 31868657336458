import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  createVehicleMessage as _createVehicleMessage,
  updateVehicleMessage as _updateVehicleMessage,
} from '../../../actions/vehicleActions';
import {
  createMessage as _createMessage,
  updateMessage as _updateMessage,
} from '../../../actions/messageActions';
import {
  getNumberValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import CBModal from '../../shared/CBModal';
import CBDropzone from '../../shared/CBDropzone';
import ImagePreview from '../../shared/ImagePreview';
import { ListBox } from 'primereact/listbox';



class AddMessageModal extends Component {
  constructor(props) {
    super(props);

    const { message, vehicle } = props;

    const selectedVehicle = vehicle ? vehicle.id : undefined;

    this.state = {
      formData: {
        subject: message ? message.subject : '',
        user: message ? message.user : undefined,
        text: message ? message.text : '',
        vehicle: message ? message.vehicle : selectedVehicle,
        vehicle_ids: [],
        create_task: false,
        images: message ? message.images : [],
        image_actions: [],
      },
      formValid: {
        subject: !!message,
        text: !!message,
      },
    };
  }

  componentDidMount = () => {
    const { user, vehicle, selectedVehicles, message } = this.props;
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
        user: message
          ? message.user
          : user.id,
      },
    };
    if (vehicle) {
      newState.formData.vehicle = vehicle.id;
    } else if(selectedVehicles) {
      newState.formData.vehicle_ids = selectedVehicles.map((x) => x.id);
    }

    this.setState(newState);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createVehicleMessage,
      updateVehicleMessage,
      createMessage,
      updateMessage,
      message,
      closeModal,
      vehicle,
      user,
      service,
      selectedWorkshopId,
      isListRequest
    } = this.props;

    const updateMessageAction = vehicle && !isListRequest ? updateVehicleMessage : updateMessage;
    const createMessageAction = vehicle && !isListRequest ? createVehicleMessage : createMessage;

    if (message) {
      updateMessageAction(message.id, formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    } else {
      createMessageAction(formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
    closeModal();
  };

  onDropImage = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageChanged(reader.result, file);
      };
    });
  };

  imageChanged = (image, file) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.image_actions.push(
      {
        action: 'create',
        data: image,
        preview: file.preview,
      },
    );
    this.setState({ formData: newFormData });
  };

  deleteImage = (image) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    // Is newly added image, not yet saved to backend
    if (image.preview) {
      newFormData.image_actions = newFormData.image_actions.filter(
        (img) => img.preview !== image.preview,
      );
    } else {
      newFormData.image_actions.push(
        {
          action: 'delete',
          id: image.id,
        },
      );
      newFormData.images = newFormData.images.filter(
        (img) => img.id !== image.id,
      );
    }
    this.setState({ formData: newFormData });
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      message,
      user,
      vehicle,
      vehicles
    } = this.props;

    const newImages = formData.image_actions.filter((ia) => ia.action === 'create');

    const vehicle_ids = [...formData.vehicle_ids];
    const vehicle_id = formData.vehicle;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            contentLabel='Add message modal'
            isValid={(vehicle_ids.length || vehicle_id) && this.getIsValid()}
            submitForm={this.submitForm}
            submitText={t('addMessageSaveButton')}
            cancelText='Peruuta'
            title={message ? t('updateMessageLabel') : t('addMessageLabel')}
            vehicleText={vehicle && !vehicles ? vehicle.registration_number : null}
            descriptionText='Uusi viesti ajoneuvolle'
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {
                vehicles && (
                  <div style={getStyle(screenSize, styles, 'formComponentContainer')}>
                    <ListBox
                      filter
                      multiple
                      value={vehicles.filter((veh) => vehicle_ids.includes(veh.id))}
                      onChange={(e) => { this.updateFormState({ target: 'vehicle_ids', value: e.value.map((val) => val.id) }); }}
                      options={vehicles}
                      optionLabel='display_label'
                      className='w-full md:w-14rem'
                    />
                  </div>
                )
              }
              <div style={getStyle(screenSize, styles, 'formComponentContainer')}>
                <CBInputField
                  name='subject'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.subject}
                  placeholder=''
                  onChange={this.updateFormState}
                  validators={[getLengthValidator(1, 255)]}
                  isValidCallback={this.setIsValid}
                  labelText={t('addMessageSubjectLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'formComponentContainer')}>
                <CBTextAreaField
                  name='text'
                  value={formData.text}
                  onChange={this.updateFormState}
                  labelText={t('addMessageTextLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  validators={[getLengthValidator(1, 5000)]}
                  isValidCallback={this.setIsValid}
                  validateOnInput
                />
              </div>

              <div style={getStyle(screenSize, styles, 'dropZoneContainer')}>
                <CBDropzone
                  className='opacity-on-hover'
                  onDropAccepted={this.onDropImage}
                >
                  <p style={getStyle(screenSize, styles, 'dropZoneText')}>
                    Lisää kuvia...
                  </p>
                </CBDropzone>
              </div>
              <div style={getStyle(screenSize, styles, 'imagesContainer')}>
                {
                  !!formData.images.length && (
                    formData.images.map((image) => (
                      <ImagePreview
                        key={image.id}
                        src={image.thumbnail}
                        onDelete={() => this.deleteImage(image)}
                      />
                    ))
                  )
                }
                {
                  !!newImages.length && (
                    newImages.map((image) => (
                      <ImagePreview
                        key={image.preview}
                        src={image.preview}
                        onDelete={() => this.deleteImage(image)}
                      />
                    ))
                  )
                }
              </div>
              {
                !message && (
                  <CBLabelAndCheckbox
                    color='blue'
                    checked={formData.create_task}
                    labelText={t('createTaskLabel')}
                    onChange={() => this.updateFormState({ target: 'create_task', value: !formData.create_task })}
                    additionalStyles={{ marginBottom: 16 }}
                  />
                )
              }
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 32px 16px 32px'
    },
    formComponentContainer: {
      marginBottom: 16,
    },
    regNumsText: {
      fontSize: 16,
      color: '#0095da',
      fontFamily: 'TitilliumWeb-SemiBold',
      textAlign: 'center',
    },
    imagesContainer: {
      marginBottom: 16,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dropZoneContainer: {
      marginBottom: 16,
    },
    dropZoneText: {
      color: 'rgb(102, 102, 102)',
      fontSize: 24,
    },
    inputLabelText: {
      color: 'black',
    },
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
      cursor: 'pointer',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    select: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      textAlignLast: 'center',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    kmInput: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 195,
    },
    inputCol: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleMessage: _createVehicleMessage,
    updateVehicleMessage: _updateVehicleMessage,
    createMessage: _createMessage,
    updateMessage: _updateMessage,
  },
)(translate('VehicleDetailsView')(AddMessageModal));
