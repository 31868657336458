import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import {
  fetchVehicle as _fetchVehicle,
  fetchWSVehicle as _fetchWSVehicle,
  clearCurrentVehicle as _clearCurrentVehicle,
} from '../../actions/vehicleActions';
import CBSpinner from '../shared/CBSpinner';
import WSVehicleDetailsTop from './WSVehicleDetailsComponents/TopSection/WSVehicleDetailsTop';
import VehicleDetailsMainMaintenance from '../VehicleDetailsView/VehicleDetailsComponents/VehicleDetailsMainMaintenance';
import VehicleDetailsMainTreatment from '../VehicleDetailsView/VehicleDetailsComponents/VehicleDetailsMainTreatment';
import VehicleDetailsMaintenanceMessageForm from '../VehicleDetailsView/VehicleDetailsComponents/VehicleDetailsMaintenanceMessageForm';
import VehicleDetailsMaintenanceReportButton from '../VehicleDetailsView/VehicleDetailsComponents/VehicleDetailsMaintenanceReportButton';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import VehicleDetailsReminders from '../VehicleDetailsView/VehicleDetailsComponents/Reminders/VehicleDetailsReminders';
import WSVehicleDetailsNavbar from './WSVehicleDetailsComponents/NavBar/WSVehicleDetailsNavbar';

class WSVehicleDetailsView extends React.Component {
  componentWillMount = () => {
    const {
      match,
      fetchVehicle,
      fetchWSVehicle,
      user,
      selectedWorkshopId
    } = this.props;
    fetchVehicle(match.params.vehicleId, {workshop_id: selectedWorkshopId});
  };

  componentWillReceiveProps(nextProps) {
    const { vehicleRequest, history } = this.props;
    if (
      !vehicleRequest.vehicle
      || !vehicleRequest.vehicle.maintenances
      || !nextProps.vehicleRequest.vehicle
      || !nextProps.vehicleRequest.vehicle.maintenances
    ) {
      return;
    }
    const { vehicle } = vehicleRequest;
    const { maintenances } = vehicle;
    const nextMaintenances = nextProps.vehicleRequest.vehicle.maintenances;
    const maintenanceIds = maintenances.map(m => m.id);
    const nextMaintenanceIds = nextMaintenances.map(m => m.id);
    const newMaintenanceIds = nextMaintenanceIds.filter(id => !maintenanceIds.includes(id));
    /*
    if (newMaintenanceIds.length === 1) {
      // Redirect user to the maintenance that was created
      history.push(`/workshop-vehicle/${vehicle.id}/maintenances/${newMaintenanceIds[0]}`);
    }
    */
  }

  componentWillUnmount = () => {
    this.props.clearCurrentVehicle();
  }

  navigateBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { vehicleRequest, user, history } = this.props;
    if (vehicleRequest.vehicleStatus.loading || !vehicleRequest.vehicle) {
      return <CBSpinner />;
    } else if (vehicleRequest.vehicleStatus.error) {
      return <p>{vehicleRequest.vehicleStatus.error}</p>;
    } else {
      const { vehicle } = vehicleRequest;
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'detailsContainer')}>
              <WSVehicleDetailsTop
                isUploadingImage={vehicleRequest.imageStatus.loading}
                vehicle={vehicle}
                user={user}
                navigateBack={this.navigateBack}
              />
              <WSVehicleDetailsNavbar vehicle={vehicle} history={history} />
              <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
                <div style={getStyle(screenSize, styles, 'mainContentLeft')}>
                  <Route
                    exact
                    path='/workshop-vehicle/:vehicleId/'
                    render={() => (
                      <Redirect to='maintenances/' />
                    )}
                  />
                  <Route
                    path='/workshop-vehicle/:vehicleId/maintenances'
                    render={() => (
                      <VehicleDetailsMainMaintenance vehicle={vehicle} />
                    )}
                  />
                  <Route
                    path='/workshop-vehicle/:vehicleId/treatments'
                    render={() => (
                      <VehicleDetailsMainTreatment vehicle={vehicle} />
                    )}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'reminderContainer')}>
                  {screenSize !== 'large' && (
                    <VehicleDetailsReminders vehicle={vehicle} />
                  )}
                </div>
                <div style={getStyle(screenSize, styles, 'mainContentRight')}>
                  <Route
                    path='/workshop-vehicle/:vehicleId/maintenances/:maintenanceId'
                    component={VehicleDetailsMaintenanceReportButton}
                  />
                  <Route
                    path='/workshop-vehicle/:vehicleId/maintenances/:maintenanceId'
                    component={VehicleDetailsMaintenanceMessageForm}
                  />
                </div>
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    mainContentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 50,
    },
    mainContentLeft: {
      flexBasis: '70%',
    },
    mainContentRight: {
      flexBasis: '30%',
    },
  },
  medium: {
    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'normal',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicle: _fetchVehicle,
    fetchWSVehicle: _fetchWSVehicle,
    clearCurrentVehicle: _clearCurrentVehicle,
  },
)(WSVehicleDetailsView);
