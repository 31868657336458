import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchUsers(pageNumber, search, filters) {
  let filterString = '';

  if (filters) {
    Object.keys(filters).forEach((key) => {
      filterString += `&${key}=${filters[key]}`;
    });
  }

  return {
    [API]: {
      method: GET,
      url: search || filterString ? `${API_URL}/uiapi/1/user/?page=${pageNumber}&search=${search}${filterString}` : `${API_URL}/uiapi/1/user/?page=${pageNumber}`,
      types: ['FETCH_USERS_REQUEST', 'FETCH_USERS_SUCCESS', 'FETCH_USERS_FAIL'],
      additionalData: {
        search: search || '',
        filterString: filterString || '',
      },
    },
  };
}

export function updateUserProfileStaff(profileData, userId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/user_profile/${userId}/`,
      types: ['UPDATE_PROFILE_STAFF_REQUEST', 'UPDATE_PROFILE_STAFF_SUCCESS', 'UPDATE_PROFILE_STAFF_FAIL'],
      data: profileData,
      additionalData: {
        userId,
      },
    },
  };
}
