import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle, getCsrfToken, TYPE_CARE_SERVICE } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import {
  getHetuValidator,
  getRegistrationNumberValidator,
} from '../../../utils/validators';
import {
  requestVehiclePermission as _requestVehiclePermission,
} from '../../../actions/vehiclePermissionActions';
import {
  addCareServiceVehicle as _addCareServiceVehicle,
  requestGarageVehiclePermission as _requestGarageVehiclePermission,
} from '../../../actions/vehicleActions';
import { browserIsIE } from '../../../ie-support';
import CBButtonLink from '../../shared/CBButtonLink';
import { ListBox } from 'primereact/listbox';


Modal.setAppElement('#root');

class AddCarModal extends Component {
  state = {
    formData: {
      registrationNumber: '',
      hetu: '',
      garage: null,
    },
    formValid: {
      registrationNumber: false,
      hetu: true,
    },
    addOrganizationCar: false,
  };

  componentDidMount = () => {
    const { isOrganization } = this.props;
    this.setState({ addOrganizationCar: isOrganization });
  };

  toggleAddOrganizationCar = () => {
    const { addOrganizationCar } = this.state;
    this.setState({ addOrganizationCar: !addOrganizationCar });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  isConfirmedOrganization = () => {
    const {
      garages,
    } = this.props;

    return garages && garages.length && garages[0].organization_is_confirmed;
  };

  renderBuyButton = (hasMaxSubscriptions) => {
    if (this.getIsValid() && !hasMaxSubscriptions) {
      return (
        <button
          className='hover-green-border hover-green-text'
          id='checkout-button'
          type='submit'
        >
          Siirry maksamaan
        </button>
      );
    } else {
      return (
        <button
          id='checkout-button'
          type='submit'
          disabled
        >
          Siirry maksamaan
        </button>
      );
    }
  };

  render() {
    const { formData, addOrganizationCar } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      requestGarageVehiclePermission,
      requestVehiclePermission,
      garages,
      user,
      listType,
      addCareServiceVehicle,
      selectedWorkshopId,
    } = this.props;

    const isCareService = listType === TYPE_CARE_SERVICE;

    const hasMaxSubscriptions = user.profile.active_organization_car_subscription_count >= 2;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Add car modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {addOrganizationCar ? t('addOrganizationCarModalHeading') : t('addCarModalHeading')}
              </p>
              {
                garages && false && (
                  <div style={getStyle(screenSize, styles, 'addToGarageInfoContainer')}>
                    <p style={getStyle(screenSize, styles, 'info')}>{`${t('addingToGarageInfoText')}:`}</p>
                    <p style={getStyle(screenSize, styles, 'textGarageName')}></p>
                  </div>
                )
              }

              {
                garages && (
                  <div style={getStyle(screenSize, styles, 'garageListContainer')}>
                    <p style={getStyle(screenSize, styles, 'subTitle')}>
                      {t('Autotalli')}
                    </p>

                    <ListBox
                      filter
                      value={garages.find((gar) => gar.id === formData.garage)}
                      onChange={(e) => { this.updateFormState({ target: 'garage', value: e.value ? e.value.id : undefined }); }}
                      options={garages}
                      optionLabel='name'
                      className='w-full'
                      listStyle={{width: '400px'}}
                    />
                  </div>
                )
              }

              <p style={getStyle(screenSize, styles, 'subTitle')}>
                {t('addCarModalRegNumberHeading')}
              </p>
              <CBInputField
                name='registrationNumber'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.registrationNumber.toUpperCase()}
                placeholder={t('regNroPlaceHolder')}
                onChange={this.updateFormState}
                validators={[getRegistrationNumberValidator()]}
                isValidCallback={this.setIsValid}
                disabled={addOrganizationCar && garages && !this.isConfirmedOrganization()}
              />
              {
                addOrganizationCar && !garages && !isCareService && (
                  <div>
                    <p style={getStyle(screenSize, styles, 'subTitle')}>
                      {t('addCarModalHetuHeading')}
                    </p>

                    <CBInputField
                      name='hetu'
                      inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                      value={formData.hetu.toUpperCase()}
                      placeholder='010170-000A'
                      onChange={this.updateFormState}
                      validators={[getHetuValidator()]}
                      isValidCallback={this.setIsValid}
                    />
                  </div>
                )
              }

              {
                !isCareService && !garages && (
                  <p style={getStyle(screenSize, styles, 'info')}>
                    {addOrganizationCar ? t('addOrganizationCarModalInfoText') : t('addCarModalInfoText')}
                  </p>
                )
              }

              {
                isCareService && (
                  <div>
                    <p style={getStyle(screenSize, styles, 'info')}>
                      {t('addCareServiceVehicleInfoText')}
                    </p>
                    <p style={getStyle(screenSize, styles, 'info')}>
                      {t('addCareServiceVehiclePriceText')}
                    </p>
                  </div>
                )
              }

              {
                addOrganizationCar && garages && !this.isConfirmedOrganization() && !isCareService && (
                  <div>
                    <p style={getStyle(screenSize, styles, 'error')}>
                      {t('organizationNotConfirmedText')}
                    </p>
                    <p style={getStyle(screenSize, styles, 'info')}>
                      {t('moveToConfirm')}<a href='/api/1/authentication/create-authentication-session/'>{t('here')}.</a>
                    </p>
                  </div>
                )
              }

              {
                !garages && !isCareService && (
                  <p style={getStyle(screenSize, styles, 'info')}>
                    {addOrganizationCar ? t('addCarIndividualInfoText') : t('addCarOrganizationInfoText')}
                    <CBButtonLink onClick={this.toggleAddOrganizationCar} text={`${t('here')}.`} />
                  </p>
                )
              }

              {
                !garages && addOrganizationCar && !isCareService && (
                  <p style={getStyle(screenSize, styles, 'info')}>
                    {t('addCarPriceInfo')}
                  </p>
                )
              }

              {
                hasMaxSubscriptions && !garages && addOrganizationCar && !isCareService && (
                  <p style={getStyle(screenSize, styles, 'error')}>
                    {t('maxSubscriptionsReached')}
                  </p>
                )
              }

              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={t('cancel')}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  {
                    !garages && addOrganizationCar && !isCareService && (
                      <form action='/api/1/payments/stripe-create-checkout-session/' method='POST'>
                        {/* Add a hidden field with the lookup_key of your Price */}
                        <input type='hidden' name='lookup_key' value='org_vehicle' />
                        <input type='hidden' name='hetu' value={formData.hetu} />
                        <input type='hidden' name='registrationNumber' value={formData.registrationNumber} />
                        <input
                          type='hidden'
                          value={getCsrfToken()}
                          name='csrfmiddlewaretoken'
                        />

                        { this.renderBuyButton(hasMaxSubscriptions) }

                      </form>
                    )
                  }
                  {
                    (garages || !addOrganizationCar || isCareService) && (
                      <CBButtonSubmit
                        disabled={!this.getIsValid() || (garages && !formData.garage)}
                        onClick={() => {
                          if (garages) {
                            requestGarageVehiclePermission(
                              formData.registrationNumber,
                              formData.hetu,
                              formData.garage,
                            );
                          } else if (isCareService) {
                            addCareServiceVehicle(formData.registrationNumber, selectedWorkshopId);
                          } else {
                            requestVehiclePermission(
                              formData.registrationNumber,
                              formData.hetu,
                              null,
                            );
                          }
                          closeModal();
                        }}
                        text={t('addVehicle')}
                        style={getStyle(screenSize, styles, 'submitButton')}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    garageListContainer: {
      marginBottom: 16,
    },
    addToGarageInfoContainer: {
      marginBottom: 32,
      marginTop: 32,
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: '80%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textGarageName: {
      fontSize: 18,
      color: '#0095da',
      fontFamily: 'TitilliumWeb-SemiBold',
      textAlign: 'center',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      border: 'none',
      marginBottom: 30,
      padding: 8,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    error: {
      width: '100%',
      fontSize: 14,
      color: 'red',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    requestVehiclePermission: _requestVehiclePermission,
    requestGarageVehiclePermission: _requestGarageVehiclePermission,
    addCareServiceVehicle: _addCareServiceVehicle,
  },
)(translate('GarageView')(AddCarModal));
