import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET } from '../../../utils/utils';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  fetchOrganizations as _fetchOrganizations,
  updateOrganizationPermission as _updateOrganizationPermission,
  createOrganizationPermission as _createOrganizationPermission,
  deleteOrganizationPermission as _deleteOrganizationPermission,
  toggleOrganizationMessageSubscription as _toggleOrganizationMessageSubscription,
} from '../../../actions/organizationActions';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import { withRouter } from 'react-router';
import PermissionRow from './PermissionRow';


class SettingsOrganizationPermissions extends React.Component {
  state = {
    selectedOrganization: undefined,
  };

  componentDidMount() {
    const { fetchOrganizations } = this.props;
    fetchOrganizations({ordering: 'name'});
  }

  componentWillReceiveProps(nextProps) {
    const { selectedOrganization } = this.state;
    const { user } = this.props;

    const organizations = this.getOrganizations();

    if ((!organizations || !organizations.length) && (nextProps.organizations && nextProps.organizations.length)) {
      this.setState({
        selectedOrganization: organizations[0],
      });
    }

    if (nextProps.organizations && nextProps.organizations.length && selectedOrganization) {
      this.setState({
        selectedOrganization: nextProps.organizations.find((organization) => organization.id === selectedOrganization.id),
      });
    }
  }

  getOrganizations = () => {
    const { organizations, service, selectedOrganizationId } = this.props;

    if (service === SERVICE_FLEET && selectedOrganizationId) {
      return organizations.filter((org) => org.id == selectedOrganizationId);
    }

    return organizations;
  };

  getDisplayTextForRole = (role) => {
    const { t } = this.props;

    const roleDisplayMap = {
      employee: t('roleEmployee'),
      manager: t('roleManager'),
      admin: t('roleAdmin'),
    };

    return roleDisplayMap[role];
  };


  getOrganizationOptions = () => {
    const organizations = this.getOrganizations();

    return organizations.map((organization) => {
      return {
        label: organization.name,
        value: organization.id,
      };
    });
  };

  organizationChanged = (selected) => {
    const organizationId = Number.parseInt(selected.value);
    const organizations = this.getOrganizations();

    this.setState({
      selectedOrganization: organizations.find((organization) => organizationId === organization.id),
    });
  };

  getSelectedOrganization = () => {
    const organizations = this.getOrganizations();

    let { selectedOrganization } = this.state;

    if (!selectedOrganization && organizations.length) {
      [selectedOrganization] = organizations;
    }

    return selectedOrganization;
  };

  canEditPermission = (permission, userRole) => {
    const { user } = this.props;

    if (user.is_staff) {
      return true;
    }

    if (permission.user && user.id === permission.user.id) {
      return false;
    }

    return userRole === 'admin';
  };

  messageSubscriptionChanged = (value) => {
    const { toggleOrganizationMessageSubscription } = this.props;
    toggleOrganizationMessageSubscription(
      this.getSelectedOrganization().id,
      { has_message_subscription: value },
    );
  };

  render() {
    const {
      t,
      updateOrganizationPermission,
      createOrganizationPermission,
      deleteOrganizationPermission,
      service,
      selectedOrganizationId
    } = this.props;

    const selectedOrganization = this.getSelectedOrganization();

    const isAllowedToAddPermissions = selectedOrganization && ['admin', 'manager'].includes(selectedOrganization.user_role);

    const permissions = selectedOrganization ? selectedOrganization.permissions : [];

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBSubHeading>
              {t('organizationPermissionsHeading')}
            </CBSubHeading>

            <div style={getStyle(screenSize, styles, 'organizationSelectorContainer')}>
              <CBSelectField
                containerStyle={getStyle(screenSize, styles, 'selectContainer')}
                inputStyle={getStyle(screenSize, styles, 'selectField')}
                optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                options={this.getOrganizationOptions()}
                placeholder=''
                labelText={t('labelOrganization')}
                value={selectedOrganization && selectedOrganization.id}
                onChange={this.organizationChanged}
                disabled={service === SERVICE_FLEET && selectedOrganizationId}
              />
            </div>

            {
              selectedOrganization && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'permissionTypeContainer')}>
                    <CBLabelAndValue
                      label={t('permissionType')}
                      value={this.getDisplayTextForRole(
                        selectedOrganization && selectedOrganization.user_role,
                      )}
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'switchContainer')}>
                    {/*
                      <CBLabelAndSwitch
                        labelText={t('receiveCareServiceNotificationsLabel')}
                        size='medium'
                        checked={selectedOrganization.user_has_message_subscription}
                        onChange={this.messageSubscriptionChanged}
                      />
                    */}

                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('userTableHeading')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('descriptionTableHeading')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('permissionTableHeading')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        permissions && permissions.map((permission) =>
                          (
                            <PermissionRow
                              key={permission.id}
                              permission={permission}
                              updatePermission={updateOrganizationPermission}
                              createPermission={createOrganizationPermission}
                              deletePermission={deleteOrganizationPermission}
                              canEdit={this.canEditPermission(permission, selectedOrganization.user_role)}
                              userRole={selectedOrganization.user_role}
                              isOrganizationPermission
                            />
                          ))
                      }
                      {
                        isAllowedToAddPermissions && (
                          <PermissionRow
                            isNew
                            organization={selectedOrganization}
                            permission={{
                              user_email: '',
                              role: 'admin',
                              description: '',
                            }}
                            createPermission={createOrganizationPermission}
                            canEdit
                            userRole={selectedOrganization.user_role}
                            isOrganizationPermission
                          />
                        )
                      }
                    </tbody>
                  </table>
                </React.Fragment>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    organizationSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    switchContainer: {
      marginTop: 32,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    organizations: state.organization.organizations,
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations,
    updateOrganizationPermission: _updateOrganizationPermission,
    createOrganizationPermission: _createOrganizationPermission,
    deleteOrganizationPermission: _deleteOrganizationPermission,
    toggleOrganizationMessageSubscription: _toggleOrganizationMessageSubscription,
  },
)(withRouter(translate('Permissions')(SettingsOrganizationPermissions)));
