import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { dbDateTimeFormat, finnishDateFormat, finnishDatetimeFormat, getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import {
  createTask as _createTask,
  updateTask as _updateTask,
} from '../../../actions/taskActions';
import {
  createVehicleTask as _createVehicleTask,
  updateVehicleTask as _updateVehicleTask,
} from '../../../actions/vehicleActions';
import {
  getLengthValidator,
} from '../../../utils/validators';
import { Calendar } from 'primereact/calendar';

import CBModal from '../../shared/CBModal';
import { ListBox } from 'primereact/listbox';
import RadioButtonGroup from '../../shared/RadioButtonGroup';
import { MAX_FILE_SIZE } from '../../../config';
import CBDropzone from '../../shared/CBDropzone';
import ImagePreview from '../../shared/ImagePreview';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import moment from 'moment';


class AddTaskModal extends Component {
  constructor(props) {
    super(props);

    const { task, vehicle } = props;

    const selectedVehicle = vehicle ? vehicle.id : undefined;

    this.state = {
      formData: {
        title: task ? task.title : '',
        text: task ? task.text : '',
        vehicle: task ? task.vehicle : selectedVehicle,
        priority: task ? task.priority : 'medium',
        images: task ? task.images : [],
        due_at: task ? task.due_at : null,
        image_actions: [],
      },
      formValid: {
        title: !!task,
        text: !!task,
      },
    };
  }

  componentDidMount = () => {
    const { user, task } = this.props;
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createTask,
      createVehicleTask,
      updateVehicleTask,
      updateTask,
      task,
      closeModal,
      vehicle,
      service,
      selectedWorkshopId,
    } = this.props;

    if (task) {
      if (vehicle) {
        updateVehicleTask(task.id, formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
      } else {
        updateTask(task.id, formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
      }
    } else {
      if (vehicle) {
        createVehicleTask(formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
      } else {
        createTask(formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
      }
    }

    closeModal();
  };

  setPriority = (value) => {
    this.updateFormState({ target: 'priority', value });
  };

  getPriorityOptions = () => {
    const { t } = this.props;
    return [
      {
        label: t('priorityLow'),
        value: 'low',
      },
      {
        label: t('priorityMedium'),
        value: 'medium',
      },
      {
        label: t('priorityHigh'),
        value: 'high',
      },
    ];
  };

  onDropImage = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageChanged(reader.result, file);
      };
    });
  };

  imageChanged = (image, file) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.image_actions.push(
      {
        action: 'create',
        data: image,
        preview: file.preview,
      },
    );
    this.setState({ formData: newFormData });
  };

  deleteImage = (image) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    // Is newly added image, not yet saved to backend
    if (image.preview) {
      newFormData.image_actions = newFormData.image_actions.filter(
        (img) => img.preview !== image.preview,
      );
    } else {
      newFormData.image_actions.push(
        {
          action: 'delete',
          id: image.id,
        },
      );
      newFormData.images = newFormData.images.filter(
        (img) => img.id !== image.id,
      );
    }
    this.setState({ formData: newFormData });
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      vehicles,
      vehicle,
    } = this.props;

    const vehicleOptions = vehicles && vehicles.length ? vehicles : [];

    if (vehicle && vehicleOptions.length === 0) {
      vehicleOptions.push(vehicle);
    }

    const newImages = formData.image_actions.filter((ia) => ia.action === 'create');
    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Uusi tehtävä'
            descriptionText='Uusi tehtävä ajoneuvolle'
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
            vehicleText={vehicle && !vehicles ? vehicle.registration_number : null}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {
                vehicles && (
                  <div style={getStyle(screenSize, styles, 'inputContainer')}>
                    <ListBox
                      filter
                      value={vehicleOptions.find((veh) => veh.id === formData.vehicle)}
                      onChange={(e) => { this.updateFormState({ target: 'vehicle', value: e.value ? e.value.id : undefined }); }}
                      options={vehicleOptions}
                      optionLabel='display_label'
                      className='w-full md:w-14rem'
                    />
                  </div>
                )
              }

              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <RadioButtonGroup
                  options={this.getPriorityOptions()}
                  name='priority'
                  value={formData.priority}
                  setValue={this.setPriority}
                  title={t('priority')}
                />
              </div>

              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBInputField
                  name='title'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.title}
                  placeholder=''
                  onChange={this.updateFormState}
                  validators={[getLengthValidator(1, 40)]}
                  isValidCallback={this.setIsValid}
                  labelText={t('addTaskTitleLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div>
                <CBTextAreaField
                  name='text'
                  value={formData.text}
                  onChange={this.updateFormState}
                  labelText={t('addTaskTextLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  validators={[getLengthValidator(1, 5000)]}
                  isValidCallback={this.setIsValid}
                  validateOnInput
                />
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <p
                  className='font-titillium-web-semi-bold'
                  style={getStyle(screenSize, styles, 'dateInputLabel')}
                >
                  {t('headingDueAt')}
                </p>
                <div className='datepicker-container-white-bg'>
                  <CBDatePickerInput
                    displayedDate={formData.due_at ? moment(formData.due_at) : null}
                    type='date'
                    dateFormat={finnishDateFormat}
                    changeDate={(value) => {
                      this.updateFormState({ target: 'due_at', value: moment(value).toISOString() });
                    }}
                  />
                </div>
              </div>
              <div style={getStyle(screenSize, styles, 'dropZoneContainer')}>
                <CBDropzone
                  className='opacity-on-hover'
                  onDropAccepted={this.onDropImage}
                >
                  <p style={getStyle(screenSize, styles, 'dropZoneText')}>
                    Lisää kuvia...
                  </p>
                </CBDropzone>
              </div>
              <div style={getStyle(screenSize, styles, 'imagesContainer')}>
                {
                  !!formData.images.length && (
                    formData.images.map((image) => (
                      <ImagePreview
                        src={image.thumbnail}
                        onDelete={() => this.deleteImage(image)}
                      />
                    ))
                  )
                }
                {
                  !!newImages.length && (
                    newImages.map((image) => (
                      <ImagePreview
                        src={image.preview}
                        onDelete={() => this.deleteImage(image)}
                      />
                    ))
                  )
                }
              </div>
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    inputContainer: {
      marginBottom: 16,
    },
    imagesContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dropZoneContainer: {
      marginTop: 16,
    },
    dropZoneText: {
      color: 'rgb(102, 102, 102)',
      fontSize: 24,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createTask: _createTask,
    updateTask: _updateTask,
    createVehicleTask: _createVehicleTask,
    updateVehicleTask: _updateVehicleTask,
  },
)(translate('TaskListView')(AddTaskModal));
